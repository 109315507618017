import { Controller } from '@hotwired/stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ 'checkbox', 'query' ];

  connect(){
    this.boundUpdateURL = this.updateURL.bind(this);
    this.element.addEventListener('turbo:submit-end', this.boundUpdateURL);
    this.processFilterCountMutation(this.element);
  }

  updateURL(event) {
    // Push state or replace state?
    // Turbo.navigator.history.replace
    window.history.pushState(window.history.state, null, event.detail.fetchResponse.response.url);
    this.queryTarget.blur();
  }

  checkboxTargetConnected(target) {
    target.addEventListener('change', () => {
      Rails.fire(this.element, 'submit');
    });
  }

  processFilterCountMutation(element) {
    const config = { childList: true, subtree: true, characterData: true };

    // Callback function to execute when mutations are observed
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if ( mutation.target.classList.contains('filter-count') ){
          const activeFilterCount = parseInt(mutation.target.innerHTML);
          if ( activeFilterCount > 0){
            mutation.target.classList.remove('hidden');
          } else {
            mutation.target.classList.add('hidden');
          }
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(element, config);

    // Later, you can stop observing
    // observer.disconnect();
  }
}
