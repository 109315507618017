import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    timeout: { type: Number, default: 3000 }
  };

  connect() {
    this.timeout = setTimeout(this.hideFlash.bind(this), this.timeoutValue);
  }

  close() {
    this.hideFlash();
    clearTimeout(this.timeout);
  }

  hideFlash() {
    this.element.style.display = "none";
  }
}
