import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js";

export default class extends Controller {
  static values = {
    extraPageViewParams: Object
  }

  initialize() {
    this.processTrackingEvents = this.processTrackingEvents.bind(this);
  }

  connect(){
    document.addEventListener('turbo:load', this.processTrackingEvents);
    document.addEventListener('turbo:submit-end', this.processTrackingEvents);
  }

  disconnect() {
    document.removeEventListener('turbo:load', this.processTrackingEvents);
    document.removeEventListener('turbo:submit-end', this.processTrackingEvents);
  }

  async processTrackingEvents(e){
    let platformData = { platform: null, platformVersion: null };
    if (navigator.userAgentData) {
      try {
        const highEntropyData = await navigator.userAgentData.getHighEntropyValues(['platform', 'platformVersion']);
        platformData = {
          platform: highEntropyData.platform,
          platformVersion: highEntropyData.platformVersion
        };
      } catch (error) {
        console.error('Error fetching high entropy values:', error);
      }
    }

    let pageViewed = {
      url: e.detail.url || window.location.href,
      path: window.location.pathname,
      referrer: document.referrer || null,
      user_agent: navigator.userAgent,
      screen_width: window.screen.width,
      screen_height: window.screen.height,
      language: navigator.language || null,
      platform: platformData.platform || navigator.platform || null,
      platform_version: platformData.platformVersion,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    const payload = Object.assign({}, pageViewed, this.extraPageViewParamsValue)
    this.sendEvent(payload);
  }

  sendEvent(payload) {
    post(document.body.dataset.eventsUrl, {
      responseKind: 'json',
      body: payload
    });
  }
}
